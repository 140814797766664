import React from 'react';
import WebsiteLayout from "../components/website/Layout"
import MainContent from "../components/website/MainContent";
import axios from "axios"

function Index({ count }) {
	return (
		<>
			<MainContent businessesCount={count} />
		</>
	)
}

export async function getServerSideProps() {
	let count
	try {
		const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_BASE}/api/businessesCount/`)
		count = data?.count || 4300
	} catch (err) {
		count = 4300
	}
	return {
		props: { count }
	}
}

Index.Layout = WebsiteLayout;
export default Index
